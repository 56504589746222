// react & gatsby modules
import * as React from "react"

// components
import Seo from "../layout/seo";
import Layout from "../layout/layout";
import {Link} from "gatsby";

const Page404 = ({ data, location }) => {

  return <Layout>
    <section className="abount-program container"><h2>PAGE NOT FOUND</h2>
      <div><p>Are you sure the website URL is correct?</p>
        <Link href={'/'} className={'button-blue'}>Go Back Home</Link>
      </div>
    </section>
  </Layout>
}

export const Head = () => <Seo
  title='Error 404. Page Not Found'
/>

export default Page404